import React, { useState } from 'react';
import { Clock, Users, ArrowUp, Shield, ArrowRight, X, Check } from 'lucide-react';

const Slide = ({ children, className }) => (
    <div className={`h-screen w-screen flex items-center justify-center p-8 ${className}`}>
        {children}
    </div>
);

const InitiativeSpectrumSlides = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const keyPoints = [
        {
            icon: <Clock className="text-blue-500" size={48} />,
            title: "Time Management",
            concept: "Avoid accumulating and owning employees tasks & problems.",
            application: "Focus on important tasks, not just urgent ones. Set up regular check-ins instead of allowing constant interruptions."
        },
        {
            icon: <Users className="text-green-500" size={48} />,
            title: "Effective Delegation",
            concept: "Empower employees to handle their own responsibilities.",
            application: "When issues arise, guide employees to develop solutions rather than solving problems for them."
        },
        {
            icon: <ArrowUp className="text-yellow-500" size={48} />,
            title: "Initiative Spectrum",
            concept: "Encourage higher levels of employee initiative.",
            application: "Move from 'waiting for instructions' towards 'act autonomously, report routinely'."
        },
        {
            icon: <Shield className="text-purple-500" size={48} />,
            title: "Trust and Development",
            concept: "Build a culture of trust and continuous improvement.",
            application: "Invest in developing employees' skills and create a psychologically safe environment for taking initiative."
        }
    ];

    const levels = [
        { number: 1, text: "Wait for instructions", description: "Lowest initiative", status: "unacceptable" },
        { number: 2, text: "Ask for guidance", description: "Seeks direction", status: "unacceptable" },
        { number: 3, text: "Suggest and act if approved", description: "Proposes solutions", status: "acceptable" },
        { number: 4, text: "Act independently, report immediately", description: "Takes action", status: "preferred" },
        { number: 5, text: "Act autonomously, report routinely", description: "Highest initiative", status: "most preferred" }
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'unacceptable': return 'bg-red-100 border-red-300';
            case 'acceptable': return 'bg-yellow-100 border-yellow-300';
            case 'preferred':
            case 'most preferred': return 'bg-green-100 border-green-300';
            default: return 'bg-blue-50 border-blue-200';
        }
    };

    const StatusIcon = ({ status }) => {
        if (status === 'unacceptable') return <X className="text-red-500 absolute top-2 right-2" size={24} />;
        if (status === 'preferred' || status === 'most preferred') return <Check className="text-green-500 absolute top-2 right-2" size={24} />;
        return null;
    };

    const slides = [
        // Slide 1: Title
        <Slide key="title" className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <div className="text-center">
                <h1 className="text-5xl font-bold mb-4">Managing Time and Empowering Teams</h1>
                <p className="text-2xl">Cultivating Initiative, Liberating Leadership</p>
            </div>
        </Slide>,

        // Slide 2: Updated Key Concepts Overview with Citations
        <Slide key="overview" className="bg-gray-100">
            <div className="text-center max-w-4xl">
                <h2 className="text-3xl font-bold mb-8">Key Management Concepts</h2>
                <div className="grid grid-cols-2 gap-8 mb-8">
                    {keyPoints.map((point, index) => (
                        <div key={index} className="flex items-center">
                            {point.icon}
                            <span className="ml-4 text-xl">{point.title}</span>
                        </div>
                    ))}
                </div>
                <div className="text-sm text-gray-600 mt-8">
                    <p>Based on the work of Oncken, Wass, and Covey:</p>
                    <p>Oncken, W., Jr., & Wass, D. L. (1974). Management time: Who's got the monkey? Harvard Business Review</p>
                    <p>Covey, S. R. (1999). Making time for gorillas. Harvard Business Review</p>
                </div>
            </div>
        </Slide>,

        // Slides 3-6: Individual Key Points
        ...keyPoints.map((point, index) => (
            <Slide key={`keypoint-${index}`} className="bg-white">
                <div className="max-w-2xl">
                    <div className="flex items-center mb-6">
                        {point.icon}
                        <h2 className="text-3xl font-bold ml-4">{point.title}</h2>
                    </div>
                    <p className="text-xl mb-4"><strong>Concept:</strong> {point.concept}</p>
                    <p className="text-xl"><strong>Application:</strong> {point.application}</p>
                </div>
            </Slide>
        )),

        // Slide 7: Leadership Initiative Spectrum
        <Slide key="initiative-spectrum" className="bg-gray-100">
            <div className="w-full max-w-4xl">
                <h2 className="text-3xl font-bold mb-8 text-center">Leadership Initiative Spectrum</h2>
                <div className="flex items-stretch space-x-2">
                    {levels.map((level, index) => (
                        <React.Fragment key={level.number}>
                            <div className={`flex-1 p-4 rounded-lg shadow-md flex flex-col border-2 relative ${getStatusColor(level.status)}`}>
                                <StatusIcon status={level.status} />
                                <div className="flex items-center justify-center mb-2">
                                    <div className="w-12 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center font-bold text-lg">
                                        {level.number}
                                    </div>
                                </div>
                                <p className="font-semibold text-sm text-center text-gray-700 mb-2">{level.text}</p>
                                <p className="text-xs text-gray-500 text-center">{level.description}</p>
                            </div>
                            {index < levels.length - 1 && (
                                <div className="flex items-center">
                                    <ArrowRight className="text-blue-400" size={24} />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <div className="flex justify-between items-center text-sm text-gray-600 mt-4">
                    <span className="font-bold">Less Initiative</span>
                    <span className="font-bold">More Initiative</span>
                </div>
            </div>
        </Slide>,

        // Slide 8: Conclusion
        <Slide key="conclusion" className="bg-gradient-to-r from-green-400 to-blue-500 text-white">
            <div className="text-center max-w-2xl">
                <h2 className="text-4xl font-bold mb-6">Key Takeaways</h2>
                <ul className="text-xl space-y-4 text-left list-disc">
                    <li>Manage your time effectively by avoiding unnecessary tasks</li>
                    <li>Delegate responsibilities to empower your team</li>
                    <li>Encourage higher levels of initiative from your employees</li>
                    <li>Build a culture of trust and continuous improvement</li>
                    <li>Strive for autonomous action with routine reporting</li>
                </ul>
            </div>
        </Slide>
    ];

    const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
    const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

    return (
        <div className="relative">
            {slides[currentSlide]}
            <div className="absolute bottom-4 left-4 right-4 flex justify-between">
                <button onClick={prevSlide} className="bg-gray-800 text-white px-4 py-2 rounded">Previous</button>
                <span className="bg-gray-800 text-white px-4 py-2 rounded">{currentSlide + 1} / {slides.length}</span>
                <button onClick={nextSlide} className="bg-gray-800 text-white px-4 py-2 rounded">Next</button>
            </div>
        </div>
    );
};

export default InitiativeSpectrumSlides;